import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const configureRouter = {
  path: "/configure/admin",
  name: "configureAdmin",
  component: () => import("../../views/configure/index.vue"),
  meta: {
    icon: "36",
    title: "支付样式配置",
    rank: 3,
    roles: ["koms.no-card-present"]
  },
  hiddenChild: true, // 导航不显示下级
  children: [
  ]
};

export default configureRouter;
