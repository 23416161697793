import dataReportRouter from "./dataReport";
import dataBoardRouter from "./dataBoard";
// import homeRouter from "./home";
import transactionFlowRouter from "./transactionFlow";
import clearRouter from "./clearInfo";
import accountCenterRouter from "./accountCenter";
import orderRouter from "./order";
import fileRouter from "./file";
import settleRouter from "./settle";
import configureRouter from "./configure";
import infoRouter from "./info";
import settleChanneRouter from "./settle-channel";
// 把模块引入间来
const asyncRoutes = [
  // homeRouter,
  transactionFlowRouter,
  dataReportRouter,
  dataBoardRouter,
  // clearRouter,
  accountCenterRouter,
  fileRouter,
  orderRouter,
  settleRouter,
  configureRouter,
  infoRouter,
  settleChanneRouter
];
export default asyncRoutes;
