import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const settleChanneRouter = {
  path: "/settle-channel/admin",
  name: "settleChannelAdmin",
  component: () => import("../../views/settle-channel/detail.vue"),
  meta: {
    icon: "38",
    title: "渠道结算明细",
    rank: 8,
    roles: ["koms.channel"]
  },
  hiddenChild: true, // 导航不显示下级
};

export default settleChanneRouter;
