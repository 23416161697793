import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const dataBoardRouter = {
  path: "/",
  name: "DashBoard",
  component: Layout,
  redirect: "/data-board",
  meta: {
    icon: "6",
    title: $t("首页"),
    rank: 1
    // roles: ["koms.dashboard"]
  },
  children: [
    {
      path: "/data-board",
      name: "DataBoard",
      component: () => import("/@/views/data-board/index.vue"),
      meta: {
        title: $t("数据看板"),
        // roles: ["account.mgt"]
        showParent: false
      }
    },
    // {
    //   path: "/info",
    //   name: "InfoDetail",
    //   component: () => import("/@/views/info/index.vue"),
    //   meta: {
    //     title: $t("组织信息"),
    //     showParent: false
    //   }
    // }
  ]
};

export default dataBoardRouter;
