import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");


const infoRouter = {
  path: "/info",
  name: "InfoDetail",
  component: () => import("/@/views/info/index.vue"),
  meta: {
    icon: "34",
    title: "商户信息",
    rank: 3,
    roles: ["koms"],
    showParent:false
  },
  hiddenChild: true, // 导航不显示下级
  hidden: true,
  noShowingChildren:true
};

export default infoRouter;
