import { isUrl } from "/@/utils/is";
import { getConfig } from "/@/config";
import { toRouteType } from "./types";
import { openLink } from "/@/utils/link";
import NProgress from "/@/utils/progress";
import { findIndex } from "lodash-unified";
import { transformI18n, transformyI18n } from "/@/plugins/i18n";
import { storageSession } from "/@/utils/storage";
import { buildHierarchyTree } from "/@/utils/tree";
import { ref } from "vue";
import { getCurrencyList } from "/@/api/common";
import {
  getDefaultCurrency,
  setDashboardCurrency,
  getTimeZone
} from "/@/api/dataBoard";
import {
  getParam,
  getToken,
  getRefreshToken,
  getExpiresTimestamp,
  getAuthCode,
  setAuthCodeKey,
  getAuthCount,
  setAuthCount,
  setlanguage,
  getLocalMsTime
} from "/@/utils/auth";
// import { getCurrentInstance } from "vue"
import { keepAliveApi, gotoAuthCenter } from "/@/api/auth-token";
import {
  Router,
  RouteMeta,
  createRouter,
  RouteRecordRaw,
  RouteComponent,
  RouteRecordName
} from "vue-router";
import {
  ascending,
  initRouter,
  getHistoryMode,
  getParentPaths,
  findRouteByPath,
  handleAliveRoute,
  formatTwoStageRoutes,
  formatFlatteningRoutes,
  setNavBar,
  hasRoute,
  filterTree
} from "./utils";
import $store from "/@/store/index";
import { filterAsyncRoutes } from "/@/store/modules/permission1";

import homeRouter from "./modules/home";
import dataBoardRouter from "./modules/dataBoard";
import remainingRouter from "./modules/remaining";
import asyncRoutes from "/@/router/modules/asyncRoutes";
import { storageLocal } from "/@/utils/storage";
import transactionFlowRouter from "./modules/transactionFlow";
import { useUserStoreHook } from "../store/modules/user";
import stroe1 from "../store/index";
import { init } from "../api/home";
import { getAllRole, getMerInfo, getBankType } from "../api/common";
import { ElMessage } from "element-plus";
import { log } from "console";
import { useI18n } from "vue-i18n";

// 原始静态路由（未做任何处理）
const routes = [homeRouter, dataBoardRouter, transactionFlowRouter];

// 导出处理后的静态路由（三级及以上的路由全部拍成二级）
export const constantRoutes: Array<RouteRecordRaw> = formatTwoStageRoutes(
  formatFlatteningRoutes(buildHierarchyTree(ascending(routes)))
);

// 用于渲染菜单，保持原始层级
export const constantMenus: Array<RouteComponent> = ascending(routes).concat(
  ...remainingRouter
);

// 不参与菜单的路由
export const remainingPaths = Object.keys(remainingRouter).map(v => {
  return remainingRouter[v].path;
});

async function getRoutes() {
  let accessRoutes = [];
  // let constantRoutes = [];
  if ($store.getters.roles) {
    if ($store.getters.roles.length > 0) {
      // accessRoutes = filterAsyncRoutes(asyncRoutes, $store.getters.roles);
      accessRoutes = await $store.dispatch(
        "permission/generateRoutes",
        $store.getters.roles
      );
      // constantRoutes = formatTwoStageRoutes(
      //   formatFlatteningRoutes(buildHierarchyTree(ascending(accessRoutes)))
      // );
      accessRoutes.forEach((v: any) => {
        router.addRoute(v);
        router.options.routes.push(v);
      });
    }
  }
  // return filterTree(ascending(constantRoutes)).concat(...remainingRouter);
}

// 创建路由实例
export const router: Router = createRouter({
  history: getHistoryMode(),
  routes: [].concat(...remainingRouter), //getRoutes(), //$store.getters.permission_routes.length > 0 ? $store.getters.permission_routes : [],
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.meta.saveSrollTop) {
          const top: number =
            document.documentElement.scrollTop || document.body.scrollTop;
          resolve({ left: 0, top });
        }
      }
    });
  }
});

getRoutes();

// 路由白名单
const whiteList = ["/login", "/401", "/check", "/up-password"];
const tryAuthCount = 3;
let isb = ref(false);
// 登录成功后初始化
function initCheck() {
  init().then(res => {
    if (res.code === 20000) {
      isb.value = true;
    }
  });
}

router.beforeEach(async (to: toRouteType, _from, next) => {
  // 判断时候有语言
  if (getParam("language") || getParam("lang")) {
    const lang = getParam("language") || getParam("lang");
    setlanguage(lang);
    if (lang == "zh") {
      setlanguage("zh_CN");
    }
    if (lang == "en") {
      setlanguage("en_US");
    }
  }
  if (to.meta?.keepAlive) {
    const newMatched = to.matched;
    handleAliveRoute(newMatched, "add");
    // 页面整体刷新和点击标签页刷新
    if (_from.name === undefined || _from.name === "redirect") {
      handleAliveRoute(newMatched);
    }
  }
  const name = storageSession.getItem("info");

  const externalLink = isUrl(to?.name);
  if (!externalLink)
    to.matched.some(item => {
      if (!item.meta.title) return "";
      if (to.path === "/check") {
        document.title = transformyI18n(item.meta.title);
        return;
      }
      const Title = getConfig().Title;
      if (Title)
        document.title = `${transformyI18n(item.meta.title)} | ${Title}`;
      else document.title = transformyI18n(item.meta.title);
    });
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    keepAliveApi();
    // 获取code
    const tokenParam = {
      authCode: getParam("code"),
      redirectUri: window._CONFIG["redirectUri"]
    };
    let countNum = getAuthCount();
    if (tokenParam.authCode && tokenParam.authCode !== getAuthCode()) {
      // code存在
      // 储存授权code
      setAuthCodeKey(tokenParam.authCode);
      // code获取令牌
      await $store.dispatch("user/getToken", tokenParam).catch(e => {
        console.log("使用code换取token失败");
        setAuthCount(++countNum);
        if (countNum < tryAuthCount) {
          gotoAuthCenter();
          return false;
        } else {
          next("/401");
        }
      });
      setAuthCount(0);
    } else {
      // code不存在
      // const token = getToken();
      const token = sessionStorage.getItem("Token");
      const refreshToken = getRefreshToken();
      const expiresTimestamp = getExpiresTimestamp();
      console.log("获取缓存中的token:", token);
      if (!token) {
        console.log("没有获取到缓存中的token");
        gotoAuthCenter();
        // 调用授权中心进行授权
        // setAuthCount(++countNum);
        // if (countNum < tryAuthCount) {
        //   gotoAuthCenter();
        //   return false;
        // } else {
        //   next("/401");
        // }
      } else if (expiresTimestamp && expiresTimestamp < getLocalMsTime()) {
        // token过期
        console.log("缓存中的token已过期，需要刷新token");
        if (!refreshToken) {
          // 没有
          console.log("缓存中未找到refreshToken");
          setAuthCount(++countNum);
          if (countNum < tryAuthCount) {
            gotoAuthCenter();
            return false;
          } else {
            next("/401");
          }
        } else {
          console.log("使用缓存中的refreshToken刷新token");
          try {
            const { access_token } = await $store.dispatch(
              "user/refreshToken",
              { refreshToken: refreshToken }
            );
            if (typeof access_token !== "undefined" && access_token) {
              console.log("刷新token成功，重置授权次数");
              setAuthCount(0);
            } else {
              console.log("刷新token失败");
              setAuthCount(++countNum);
              if (countNum < tryAuthCount) {
                gotoAuthCenter();
                return false;
              } else {
                next("/401");
              }
            }
          } catch (e) {
            console.log("刷新token异常", e);
            next("/401");
            // setAuthCount(++countNum)
            // if (countNum < tryAuthCount) {
            //   gotoAuthCenter()
            //   return false
            // } else {
            //   next('/401')
            // }
          }
        }
      } else {
        setAuthCount(0);
      }
    }
    //获取roleCode
    if (getParam("roleCode")) {
      console.log('getParam("roleCode")================', getParam("roleCode"));
      $store.commit("user/SET_SELECTEDROLE", getParam("roleCode"));
    }
    NProgress.start();
    const hasToken = getToken();

    if (hasToken) {
      const hasRoles = $store.getters.roles && $store.getters.roles.length > 0;
      const hasRoutes =
        $store.getters.routes && $store.getters.routes.length > 0;
      if (hasRoles && hasRoutes) {
        next();
      } else {
        try {
          // const { customInfo } = await useUserStoreHook().getCustom();
          // const { res } = await stroe1.dispatch("user/getCustom");
          const { user } = await $store.dispatch("user/getAuths");
          // if(user.modifyInitPwd === 1){
          //   sessionStorage.setItem("frist",true);
          //   next('/up-password');
          //   // return
          // }
          await getAllRole().then(res => {
            if (res.code === 20000) {
              storageSession.setItem("allRoleList", res.result);
            }
          });

          const { roles, type } = await $store.dispatch("user/getRealAuths");
          const { customerId } = await $store.dispatch("user/getCustomReal");
          await $store.dispatch("user/getStoreReal");

          let canRedirect = true;
          // await initCheck();
          const { initRes } = await $store.dispatch("user/initUser");
          if (initRes?.code === 20000) {
            // 默认币种
            // 币种选择数据
            const cnyOption = ref([]);
            await getCurrencyList({
              customerId: $store.getters.customerId,
              storeId:
                $store.getters.selectedRole === "koms.storeId.admin"
                  ? $store.getters.storeId
                  : ""
            }).then(res => {
              if (res.code === 20000) {
                cnyOption.value = res.result.map(item => {
                  return {
                    label: item.abbreviation,
                    value: item.durrency,
                    decimal: item.decimal,
                    imgUrl: item.imgUrl
                  };
                });
                storageSession.setItem("currencyList", cnyOption.value);
              }
            });

            await getMerInfo().then(res => {
              if (res.code === 20000) {
                let result = res.result;
                let abilArr = [];
                if (result?.supportNoCardPresent === "1") {
                  abilArr.push("银行卡在线支付");
                }
                if (result?.supportQrcode === "1") {
                  abilArr.push("扫码支付");
                }
                if (result?.supportVisa === "1") {
                  abilArr.push("VISA分期");
                }
                //本地模拟
                // abilArr = ["银行卡在线支付", "扫码支付", "VISA分期"];

                storageSession.setItem("abilList", abilArr);
              }
            });

            let bankList = [];
            getBankType().then(res => {
              if (res.code === 20000) {
                bankList = res.result;
                storageSession.setItem("bList", bankList);
              }
            });

            //获取当前登录用户时区信息
            await getTimeZone().then(res => {
              if (res.code === 20000) {
                if (!res.result.offset) {
                  canRedirect = false;
                }
                //储存时区信息
                sessionStorage.setItem("offset", res.result.offset);
                sessionStorage.setItem("timeZone", res.result.timeZone);
              } else {
                canRedirect = false;
              }
            });
            // 获取默认币种
            await getDefaultCurrency().then(res => {
              if (res.code === 20000) {
                sessionStorage.setItem("defaultCurrency", res.result);
                sessionStorage.setItem("currency", res.result);
              }
            });
          }

          if (type === "koms.store.manage") {
            await $store.dispatch("user/getStoreReal");
          }

          const accessRoutes = await $store.dispatch(
            "permission/generateRoutes",
            roles
          );
          accessRoutes.forEach((v: any) => {
            router.addRoute(v);
            router.options.routes.push(v);
          });

          if (initRes?.code === 20000 && canRedirect) {
            next({ ...to, replace: true });
          } else {
            const { t } = useI18n();
            if (!canRedirect) {
              ElMessage.warning({
                message: t("获取时区失败"),
                duration: 2000
              });
            }
            next("/401");
          }
        } catch (error) {
          next("/401");
          console.log("获取权限路由失败", error);
        }
      }
    } else {
      console.log("取缓存token失败，需进行授权");
      gotoAuthCenter();
      return false;
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export function resetRouter() {
  router.getRoutes().forEach(route => {
    const { name } = route;
    if (name) {
      router.hasRoute(name) && router.removeRoute(name);
    }
  });
}

export default router;
