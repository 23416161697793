import request from "../utils/request";

// 查询商户门店币种列表
export function getCurrencyList(params: Object) {
  return request({
    url: "/common/getCurrencyListByObjId",
    method: "get",
    params
  });
}
// 查询所有的角色
export function getAllRole() {
  return request({
    url: "/common/getAllRole",
    method: "get"
  });
}
// 用户新增查询角色
export function addUserGetRole() {
  return request({
    url: "/Employee/roles",
    method: "get"
  });
}
// 查询商户信息
export function getMerInfo() {
  return request({
    url: "/common/getMerchantDetail",
    method: "get"
  });
}
// 查询产品信息
export function getPerInfo() {
  return request({
    url: "/common/getProducts",
    method: "get"
  });
}
// 查询发卡行
export function getBankType() {
  return request({
    url: "/common/getCardBankType",
    method: "get"
  });
}
